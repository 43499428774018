import React from 'react'
import HospitalSvg from './HospitalSvg';

const MessageConsulting = () => {
  return (
    <div className={`dashboard-card-container bg-[#FFA901] `}>
      <div className={`dashboard-card-icon bg-[#FFB72C] `}>
        {<HospitalSvg />}
      </div>
      <div className="dashboard-card-content">
        <span className="dashboard-card-value">{16.3}k</span>
        <span className="dashboard-card-text">{"Lab Appointments"}</span>
      </div>
    </div>
  );
}

export default MessageConsulting