import { axiosAuthorized } from "../../api/config";

export const getCategories = async () => {
    try {
        const repsonse = await axiosAuthorized.get(`/getcategorypharmacy`);
        return repsonse;
    } catch (error) {
        throw error;
    }
}
export const addCategory = async (initialState, pharmacy_category_icon, categoryId = null) => {
    try {
        const reqestData = {
            ...initialState,
            pharmacy_category_icon
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        }
        const url = categoryId ? `/category_pharmacy/update_categorypharmacy` : `/category_pharmacy/add_categorypharmacy`
        const repsonse = await axiosAuthorized[categoryId ? "put" : "post"](url, reqestData, requestConfig);
        return repsonse;
    } catch (error) {
        throw error;
    }
}

export const deleteCategory = async (categoryId) => {
    try {
        const repsonse = await axiosAuthorized.delete(`/category_pharmacy/delete_categorypharmacy/${categoryId}`);
        return repsonse;
    } catch (error) {
        throw error;
    }
}