import { axiosInstance } from "../../api/config";

export const getPickUps = async () => {
    try {
        const response = await axiosInstance.get("/pharmacy/get_pharmacyappointment");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addPickUp = async (initialState, pickUpId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            patient_id: initialState.patient_id,
            gender: initialState.gender,
            age: initialState.age,
            test_name: initialState.test_name,
            doctor_name: initialState.doctor_name,
            date: initialState.date,
            time: initialState.time,
            description: initialState.description,
            status: "Out-Patient"
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url = pickUpId
            ? `/pharmacy/update_pharmacyappointment/${pickUpId}`
            : `/pharmacy/add_pharmacyappointment`;

        const response = await axiosInstance[pickUpId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deletePickUp = async (pickUpId) => {
    try {
        const response = await axiosInstance.delete(`/pharmacy/delete_pharmacyappointment/${pickUpId}`);
        return response;
    } catch (error) {
        throw error;
    }
}